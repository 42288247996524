<template>
  <div class="d-flex application-info-label">
    <span
      class="footer-info clickable app-version"
      @click.stop="showReleaseNotes()"
    >{{`${$t('t.AppTitle')} ${version}`}}</span>
    <v-dialog
      v-model="isReleaseNotesVisible"
      max-width="600"
    >
      <release-notes
        :version="version"
        :release="release"
      />
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {
    ReleaseNotes: () => import('../pages/dialogs/release-notes')
  },
  created () {
    this.getApplicationVersion()
  },
  computed: {
  },
  data () {
    return {
      isReleaseNotesVisible: false,
      release: null,
      version: null
    }
  },
  methods: {
    async getApplicationVersion () {
      const r = await this.$http().get('/core/v6/application-version')
      this.release = r.data.release
      this.version = r.data.version
    },
    showReleaseNotes () {
      this.isReleaseNotesVisible = true
    }
  },
  props: {
  }
}
</script>

<style lang="stylus" scoped></style>
